import React from "react";
import Banner from "./banner/Banner";
import About from "./about/About";
import Experience from "./experience/Experience";
import Collections from "./collections/Collections";
import Available from "./available/Available";
import Review from "./review/Review";
import Blog from "./blog/Blog";
import Gallery from "./gallery/Gallery";
import HomeCategory from "./home-category/HomeCategory";
const Home = () => {
  return (
    <>
      
      <Banner />
      <HomeCategory />
      <About />
      <Experience />
      <Collections />
      <Available />
      <Gallery />
      <Review />
      <Blog />
    </>
  );
};

export default Home;
