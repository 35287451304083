import React from "react";
import img1 from "../.../../../assets/images/collection/1.webp";
import img2 from "../.../../../assets/images/collection/2.webp";
import img3 from "../.../../../assets/images/collection/3.webp";
import img4 from "../.../../../assets/images/collection/4.webp";
import img5 from "../.../../../assets/images/collection/5.webp";
import img6 from "../.../../../assets/images/collection/6.webp";
import { FaLongArrowAltRight } from "react-icons/fa";

import { Link } from "react-router-dom";
import Available from "../home/available/Available";
import WorkStep from "./work-step/WorkStep";

const Vehicles = () => {
  return (
    <>
      <section className="collectionSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="collectionCard">
                <div className="image">
                  <img
                    src={img1}
                    alt="Vespa Piajjio 125"
                    className="img-fluid"
                  />
                </div>
                <div className="text">
                  <h5>Vespa Piajjio 125</h5>
                  <ul>
                    <li>Max passengers : 1 People</li>
                    <li>Engine Capacity : 11.5 L</li>
                    <li>Fuel : Gasoline</li>
                    <li>Fully Insured</li>
                  </ul>
                  <Link to={"/vehicle-details"}>
                    View Detail <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="collectionCard">
                <div className="image">
                  <img
                    src={img2}
                    alt="Vespa Piajjio 125"
                    className="img-fluid"
                  />
                </div>
                <div className="text">
                  <h5>E-Rickshaw Skupi</h5>
                  <ul>
                    <li>Max passengers : 1 People</li>
                    <li>Engine Capacity : 11.5 L</li>
                    <li>Fuel : Gasoline</li>
                    <li>Fully Insured</li>
                  </ul>
                  <Link to={"/vehicle-details"}>
                    View Detail <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="collectionCard">
                <div className="image">
                  <img
                    src={img3}
                    alt="Vespa Piajjio 125"
                    className="img-fluid"
                  />
                </div>
                <div className="text">
                  <h5>Jambretta 125</h5>
                  <ul>
                    <li>Max passengers : 1 People</li>
                    <li>Engine Capacity : 11.5 L</li>
                    <li>Fuel : Gasoline</li>
                    <li>Fully Insured</li>
                  </ul>
                  <Link to={"/vehicle-details"}>
                    View Detail <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="collectionCard">
                <div className="image">
                  <img
                    src={img4}
                    alt="Vespa Piajjio 125"
                    className="img-fluid"
                  />
                </div>
                <div className="text">
                  <h5>E-Rickshaw Skupi</h5>
                  <ul>
                    <li>Max passengers : 1 People</li>
                    <li>Engine Capacity : 11.5 L</li>
                    <li>Fuel : Gasoline</li>
                    <li>Fully Insured</li>
                  </ul>
                  <Link to={"/vehicle-details"}>
                    View Detail <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="collectionCard">
                <div className="image">
                  <img
                    src={img5}
                    alt="Vespa Piajjio 125"
                    className="img-fluid"
                  />
                </div>
                <div className="text">
                  <h5>Jambretta 125</h5>
                  <ul>
                    <li>Max passengers : 1 People</li>
                    <li>Engine Capacity : 11.5 L</li>
                    <li>Fuel : Gasoline</li>
                    <li>Fully Insured</li>
                  </ul>
                  <Link to={"/vehicle-details"}>
                    View Detail <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="collectionCard">
                <div className="image">
                  <img
                    src={img6}
                    alt="Vespa Piajjio 125"
                    className="img-fluid"
                  />
                </div>
                <div className="text">
                  <h5>Vespa Piajjio 125</h5>
                  <ul>
                    <li>Max passengers : 1 People</li>
                    <li>Engine Capacity : 11.5 L</li>
                    <li>Fuel : Gasoline</li>
                    <li>Fully Insured</li>
                  </ul>
                  <Link to={"/vehicle-details"}>
                    View Detail <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="text-center mt-5">
                <button type="button" className="readMore">
                  Load More
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Available />
      <WorkStep />
    </>
  );
};

export default Vehicles;
