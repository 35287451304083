import React from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <footer className="footer sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="footerBox">
                <div className="icon"><i className="ri-file-list-3-line"></i></div>
                <h3>Sign up for <br /> Electric Rikshaw</h3>
                <form className="newsLetter">
                  <label htmlFor="Email">Email Address</label>
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="form-control"
                  />
                  <button className="btn btn-primary">Subscribe</button>
                </form>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerCategory ps-3">
                <h4>Mac auto india</h4>
                <span className="line"></span>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Earum id enim, excepturi temporibus modi praesentium repellendus? Enim itaque vero, non voluptate tempora explicabo vitae magni beatae facilis atque error dicta?
                </p>
                <Link to={"about"}>Get a Quote <FaLongArrowAltRight /></Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerCategory">
                <h4>Company info</h4>
                <span className="line"></span>

                <ul>
                  <li>
                    <Link to={"/about"}> <i className="ri-arrow-right-double-line"></i> About Us</Link>
                  </li>
                  <li>
                    <Link to={"/vehicles"}> <i className="ri-arrow-right-double-line"></i> Our Vehicles</Link>
                  </li>
                  <li>
                    <Link to={"/"}> <i className="ri-arrow-right-double-line"></i> Meet Our Team</Link>
                  </li>
                  <li>
                    <Link to={"/"}> <i className="ri-arrow-right-double-line"></i> News & Media</Link>
                  </li>
                  <li>
                    <Link to={"/contact"}> <i className="ri-arrow-right-double-line"></i> Contact Us</Link>
                  </li>
                  <li>
                    <Link to={"/career"}> <i className="ri-arrow-right-double-line"></i> Careers</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerCategory">
                <h4>Quick Contact</h4>
                <span className="line"></span>

                <p className="address">121A, 121B Gurukul Indraprastha Industrial. Green Valley Faridabad, Haryana -121010</p>
                <p>If you have any questions or need help, feel free to contact with our team.</p>
                <a href="#" className="number">(+91) 8090629062</a>
              </div>
            </div>
          </div>

          <div className="copyRightSec">
            <div className="list">
              <ul>
                <li><Link to={"/"}>Where to Find Us</Link></li>
                <li><Link to={"/"}>Terms of Payment </Link></li>
                <li><Link to={"/"}> Stats Element</Link></li>
              </ul>
              <p>Copyright © 2023 Mac-auto-india, All Rights Reserved.</p>
            </div>
            <div className="social-style1">
                <Link to="/">
                  <i className="ri-facebook-fill"></i>
                </Link>
                <Link to="/">
                  <i className="ri-twitter-fill"></i>
                </Link>
                <Link to="/">
                  <i className="ri-instagram-fill"></i>
                </Link>
                <Link to="/">
                  <i className="ri-linkedin-fill"></i>
                </Link>
              </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
