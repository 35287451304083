import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import BackTop from "./components/back-top/BackTop";
import AboutPage from "./pages/about";
import VehicleDetailsPage from "./pages/vehicle-details";
import VehiclesPage from "./pages/vehicles";
import ContactPage from "./pages/contact";
import CareerPage from "./pages/career";


function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/career" element={<CareerPage />} />
        <Route path="/vehicles" element={<VehiclesPage />} />
        <Route path="/vehicle-details" element={<VehicleDetailsPage />} />
      </Routes>
      <Footer />
      <BackTop />
    </>
  );
}

export default App;
