import React from "react";

const WorkStep = () => {
  return (
    <>
      <section className="collectionSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle text-center w-75 m-auto mb-5">
                <h6>How We Work</h6>
                <h2>3 Steps To Booking a Vehicle On E-Rickshaw</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Pariatur debitis odio ut doloribus hic architecto quod fuga?
                  Ipsum esse hic, eveniet nihil commodi blanditiis culpa iure
                  nostrum, laboriosam porro facere.
                </p>
              </div>
            </div>
            </div>
            <div className="row">
            <div className="col-lg-4">
              <div className="stepCard">
                <div className="icon">
                  <i className="ri-book-open-line"></i>
                </div>
                <div className="text">
                  <h5>Booking A Vehicle</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consecte adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="stepCard">
                <div className="icon">
                  <i className="ri-bank-card-line"></i>
                </div>
                <div className="text">
                  <h5>Process The Payment</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consecte adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="stepCard">
                <div className="icon">
                  <i className="ri-bank-card-line"></i>
                </div>
                <div className="text">
                  <h5>Enjoy Your Ride</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consecte adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkStep;
