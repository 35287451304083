import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import brandLogo from "../../../assets/images/logo.webp";
import { BsArrowUpRight } from "react-icons/bs";
import BookRide from "./book-ride/BookRide";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, []);
  return (
    <>
      <header className={scroll ? "header active" : "header"}>
        <nav className="navbar navbar-expand-lg navHeader">
          <div className="container navHeaderContain">
            <Link className="brand" to="/">
              <img src={brandLogo} alt="Brand" className="img-fluid" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav menuList ms-auto">
                <li className="nav-item">
                  <Link className="nav-link active " to="/">
                    Home
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    About Us
                  </Link>

                  <ul className="dropdown-menu subDrop">
                    <li>
                      <Link className="dropdown-item" to="/about">
                        Our Story
                      </Link>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Infrastructure
                      </a>
                    </li>
                  </ul>
                </li>

                {/* 
                
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dealer
                  </Link>

                  <ul className="dropdown-menu subDrop">
                    <li>
                      <a className="dropdown-item" href="#">
                        3w Dealer
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        2w dealer
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Mac +
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Govt Suplier
                      </a>
                    </li>
                  </ul>
                </li> 
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link active dropdown-toggle"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Ticket
                  </Link>

                  <ul className="dropdown-menu subDrop">
                  <li>
                      <a className="dropdown-item" href="#">
                      Create Ticket
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                      Manage Ticket
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                      Assign Ticket
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Customer
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                      Homologation
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                      Temporary Registration
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                      Rescheduled Ticket
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                      Get Feedback
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                      Report
                      </a>
                    </li>
                  </ul>
                </li> 

                */}

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Our Products
                  </Link>

                  <ul className="dropdown-menu subDrop">
                    <li>
                      <Link className="dropdown-item" to="/vehicles">
                        Low Speed Electric Three Wheeler
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/vehicles">
                        E-Rickshaw
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/vehicles">
                        Multi Utility E-Cart
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/vehicles">
                        E-Auto Rickshaw
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/vehicles">
                        E-Loader Rickshaw
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/vehicles">
                        E-Vow
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/vehicles">
                        E-Scooter
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/vehicles">
                        E-Tricycle (ET)
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/vehicles">
                        Golf Cart
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Apply Dealership
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Become a Dealer
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/career">
                    Career
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
              <div className="exploreMenu">
                <div className="addProp">
                  <button variant="primary" onClick={handleShow}>
                    Book Now <i className="ri-motorbike-fill"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <BookRide data={{ show, handleClose }} />
    </>
  );
};

export default Header;
