import React from "react";
import img1 from "../.../../../../assets/images/collection/1.webp";
import img2 from "../.../../../../assets/images/collection/2.webp";
import img3 from "../.../../../../assets/images/collection/3.webp";
import img4 from "../.../../../../assets/images/collection/4.webp";
import { Link } from "react-router-dom";

const HomeCategory = () => {
  return (
    <>
      <section className="categorySec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h2>Category</h2>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="categoryItem">
                <Link to="/vehicles">
                  <img src={img1} alt="Category" className="img-fluid" />
                  <h6>Mac 900</h6>
                </Link>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="categoryItem">
                <Link to="/vehicles">
                  <img src={img2} alt="Category" className="img-fluid" />
                  <h6>Multi Utility E Cart</h6>
                </Link>
              </div>
            </div>
            {/* <div className="col-lg-3">
              <div className="categoryItem">
                <Link to="/vehicles">
                  <img src={img3} alt="Category" className="img-fluid" />
                  <h6>MAC Thunder</h6>
                </Link>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="categoryItem">
                <Link to="/vehicles">
                  <img src={img4} alt="Category" className="img-fluid" />
                  <h6>MAC zorawar full body</h6>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeCategory;
