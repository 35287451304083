import React from "react";

const Contact = () => {
  return (
    <>
      <section className="contactMapSec sectionPD pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="bookFormBox">
                <h3>Contact Us</h3>
                <p>Drop your details and we will get in touch.</p>
                <form className="bookForm row mt-4">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="Name">Name</label>
                      <input
                        type="text"
                        placeholder="Your Name"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Phone Number">Phone Number</label>
                      <input
                        type="number"
                        placeholder="Your Phone Number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="Email">Email</label>
                      <input
                        type="mail"
                        placeholder="Your Email"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Products">Products</label>
                      <select className="form-select">
                        <option defaultValue="Mac Haul">Mac Haul</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="Enter Your Message">
                        Enter Your Message
                      </label>

                      <textarea
                        class="form-control "
                        placeholder="Enter Your Message "
                        cols="30"
                        rows="4"
                      ></textarea>
                    </div>
                    <div className="form-group mb-3">
                      <button className="readMore b-0">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contactDetail">
                <h4>Contact Details</h4>
                <div className="item">
                  <h6>Head office Address:</h6>
                  <p>
                    121A, 121B Gurukul Indraprastha Industrial. Green Valley
                    Faridabad, Haryana -121010
                  </p>
                </div>
                <div className="item">
                  <p className="call">Call Us 24 x 7</p>
                </div>

                <div className="item">
                  <p>Sales : 8090629062</p>
                </div>
                <div className="item">
                  <p>Service Support :- service@macauto.in</p>
                </div>
                <div className="item">
                  <p className="mail">Mail Us info@macauto.in</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contactMapSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contactMap">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224612.32680993385!2d77.02370120368307!3d28.402125060430176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdc15f5a424b1%3A0xe4f50576c850e0f2!2sFaridabad%2C%20Haryana!5e0!3m2!1sen!2sin!4v1697717117480!5m2!1sen!2sin"
                  width={"100%"}
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
