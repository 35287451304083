import React from "react";
import VehicleImg from "./vehicle-img/VehicleImg";
import world from "../../assets/images/cover/world.png";
import { Tab, Tabs } from "react-bootstrap";

const VehicleDetails = () => {
  return (
    <>
      <section className="vehicleSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <VehicleImg />
              <div className="addInfo">
                <div className="name">
                  <h4>Vespa Piajjio 125</h4>
                </div>
                <div className="price">
                  <span>₹55</span>
                  <p>/Day</p>
                </div>
              </div>

              <div className="vehicleTab">
                <Tabs defaultActiveKey="Featured" id="uncontrolled-tab-example">
                  <Tab eventKey="Featured" title="Featured">
                    <div className="innerTabCard">
                      <ul>
                        <li>Geo locate</li>
                        <li>Geo fence</li>
                        <li>Bluetooth</li>
                        <li>USB charger</li>
                        <li>Anti Theft</li>
                        <li>Digital cluster</li>
                      </ul>
                    </div>
                  </Tab>
                  <Tab eventKey="Performance" title="Performance">
                    <div className="innerTabCard">
                      <div className="item">
                        <h6>Top Speed</h6>
                        <p>85 KM/H</p>
                      </div>
                      <div className="item">
                        <h6>Range on Eco Mode</h6>
                        <p>110 KM</p>
                      </div>
                      <div className="item">
                        <h6>Acceleration</h6>
                        <p>0 - 40 KM/H in 3 sec</p>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Specifications" title="Specifications">
                    <div className="innerTabCard">
                      <div className="spec">
                        <ul>
                          <li>
                            <strong>Brakes : </strong> Front Disk Brakes and
                            Rear Disk Brakes
                          </li>
                          <li>
                            <strong>Color : </strong> Red and Black
                          </li>
                          <li>
                            <strong>Tyres : </strong> Front & Rear (120/70-12)
                          </li>
                          <li>
                            <strong>Front Suspension : </strong> Telescopic
                            front fork
                          </li>
                          <li>
                            <strong>Rear Suspension : </strong> Mono
                          </li>
                          <li>
                            <strong>Carrying Capacity : </strong> 150 kg
                          </li>
                          <li>
                            <strong>Lighting : </strong> LED
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Functions" title="Functions">
                    <div className="innerTabCard">
                      <div className="spec">
                        <ul>
                          <li>Can change motorcycle sound</li>
                          <li>Urban Mode</li>
                          <li>
                            <ul className="inner">
                              <li>Reverse Mode</li>
                              <li>Parking Mode</li>
                              <li>Eco Mode</li>
                              <li>Drive Mode</li>
                              <li>Sports Mode</li>
                            </ul>
                          </li>
                          <li>Key less Ignition</li>
                        </ul>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Battery" title="Battery">
                    <div className="innerTabCard">
                      <div className="spec">
                        <ul>
                          <li>72v, 2.88 kWH</li>
                          <li>15amp Fast Charger</li>
                          <li>100% in 4-5 hours</li>
                          <li>Touch-Safe</li>
                          <li>Easily Portable</li>
                          <li>Weather Proof</li>
                          <li>BMS Battery (battery details on app)</li>
                        </ul>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="Vehicle Description"
                    title="Vehicle Description"
                  >
                    <div className="innerTabCard">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscin elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
              <div className="bookFormBox">
                <h3>Booking Now</h3>
                <form className="bookForm row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="Name">Name</label>
                      <input
                        type="text"
                        placeholder="Your Name"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Phone Number">Phone Number</label>
                      <input
                        type="number"
                        placeholder="Your Phone Number"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Pick Up Date">Pick Up Date</label>
                      <input
                        type="date"
                        placeholder="Select Date"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Pick Up Location">Pick Up Location</label>
                      <select className="form-select">
                        <option defaultValue="Vespa">Vespa</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="Email">Email</label>
                      <input
                        type="mail"
                        placeholder="Your Email"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Vehicle">Vehicle</label>
                      <select className="form-select">
                        <option defaultValue="Vespa">Vespa</option>
                      </select>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Return Date">Return Date</label>
                      <input
                        type="date"
                        placeholder="Select Date"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label
                        htmlFor="Return Date Location
                      "
                      >
                        Return Date Location
                      </label>
                      <select className="form-select">
                        <option defaultValue="Vespa">
                          Return Date Location
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <textarea
                        class="form-control "
                        placeholder="Additional Information (Optional) "
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                    <div className="form-group mb-3">
                      <button className="readMore b-0">Book now</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="positionSticky">
                <div className="colorOption">
                  <h5>Color</h5>
                  <ul>
                    <li className="green">
                      <div className="color"></div>
                      <span>Green</span>
                    </li>
                    <li className="red">
                      <div className="color"></div>
                      <span>red</span>
                    </li>
                    <li className="blue">
                      <div className="color"></div>
                      <span>blue</span>
                    </li>
                  </ul>
                </div>
                <div className="vehicleBox">
                  <h4>Ride more save more</h4>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit
                  </p>
                  <ul className="elementIconList">
                    <li>
                      <span class="elementText">1 - 3 Days</span>
                      <strong class="elementBold">Standard Rate</strong>
                    </li>
                    <li>
                      <span class="elementText">4 - 6 Days</span>
                      <strong class="elementBold">12% Off</strong>
                    </li>
                    <li>
                      <span class="elementText">7 - 11 Days</span>
                      <strong class="elementBold">18% Off</strong>
                    </li>
                    <li>
                      <span class="elementText">12 - 18 Days</span>
                      <strong class="elementBold">25% Off</strong>
                    </li>
                    <li>
                      <span class="elementText">20+ Days</span>
                      <strong class="elementBold">30% Off</strong>
                    </li>
                  </ul>
                </div>
                <div
                  className="offerBox"
                  style={{
                    backgroundImage: `url(${world})`,
                    backgroundColor: "#021F2C",
                  }}
                >
                  <div className="offerInner">
                    <h5>Discount Offer</h5>
                    <span className="text">17%</span>
                    <p>Off For All Types Of Vehicles</p>
                    <p>* New Customers Only</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VehicleDetails;
